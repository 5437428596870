.delivery-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 93px;
    width: 90%;
    /*max-width: 500px;*/
    max-width: 100vw;
    margin: 10px auto;
    background-color: #0097d2;
    color: white;
    font-weight: 600;
    -webkit-box-shadow: 2px 2px 7px 0px rgba(135, 135, 135, 1);
    -moz-box-shadow: 2px 2px 7px 0px rgba(135, 135, 135, 1);
    box-shadow: 2px 2px 7px 0px rgba(135, 135, 135, 1);
    border-radius: 7px;
    padding: 5px;
}
a:hover {
    text-decoration: none !important;
    color: white !important;
}

.parcel {
    flex: 1;
    margin: 6px 0 0 5px;
}

.delivery-details {
    flex: 3;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    margin-left: 5px;
    text-align: left;
}

.customer-name {
    color: #ebc257;
    font-weight: bold;
    margin: 0;
    font-size: 17px;
}

.delivery-details p {
    margin-bottom: 0 !important;
}

.no-deliveries {
    margin-top: 10px;
}

span {
    font-weight: bold;
}

.zoom {
    font-size: 18px;
    font-weight: 500;
    margin-top: 15px;
    margin-left: 30px;
    color: #17a2b8;
}