@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Navbar {
    background-color: #132e4f !important;
    margin: auto;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-align-items: center !important;
            align-items: center !important;
    -webkit-justify-content: center !important;
            justify-content: center !important;
    font-family: "Montserrat", sans-serif;
    color: green;
    font-weight: 700;
    height: 70px;
    width: 100%;
    border-bottom: 1px solid #707070;
}

.Navbar-brand,
.navbar-brand {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    height: 100%;
    margin-left: 16px;
}
.navbar-expand-lg {
    -webkit-flex-flow: row nowrap !important;
            flex-flow: row nowrap !important;
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
}

@media screen and (max-width: 600px) {
    .nav-logo {
        width: 150px;
    }
}
@media screen and (max-width: 374px) {
    .Navbar {
        font-size: 13px;
    }
    .nav-logo {
        width: 140px;
    }
}

.Amplify-signout {
    -webkit-align-self: end;
            align-self: end;
    justify-self: end;
}

.nav-link {
    color: rgb(255, 255, 255) !important;
    text-align: left;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}
.delivery-card {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: row;
            flex-direction: row;
    height: 93px;
    width: 90%;
    /*max-width: 500px;*/
    max-width: 100vw;
    margin: 10px auto;
    background-color: #0097d2;
    color: white;
    font-weight: 600;
    box-shadow: 2px 2px 7px 0px rgba(135, 135, 135, 1);
    border-radius: 7px;
    padding: 5px;
}
a:hover {
    text-decoration: none !important;
    color: white !important;
}

.parcel {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: 6px 0 0 5px;
}

.delivery-details {
    -webkit-flex: 3 1;
            flex: 3 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: auto;
    margin-left: 5px;
    text-align: left;
}

.customer-name {
    color: #ebc257;
    font-weight: bold;
    margin: 0;
    font-size: 17px;
}

.delivery-details p {
    margin-bottom: 0 !important;
}

.no-deliveries {
    margin-top: 10px;
}

span {
    font-weight: bold;
}

.zoom {
    font-size: 18px;
    font-weight: 500;
    margin-top: 15px;
    margin-left: 30px;
    color: #17a2b8;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.App {
    text-align: center;
    background-color: #f2f2f2 !important;
    min-height: 100vh;
    font-family: "Montserrat", sans-serif;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

