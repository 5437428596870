.Navbar {
    background-color: #132e4f !important;
    margin: auto;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-family: "Montserrat", sans-serif;
    color: green;
    font-weight: 700;
    height: 70px;
    width: 100%;
    border-bottom: 1px solid #707070;
}

.Navbar-brand,
.navbar-brand {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-left: 16px;
}
.navbar-expand-lg {
    flex-flow: row nowrap !important;
    justify-content: flex-start !important;
}

@media screen and (max-width: 600px) {
    .nav-logo {
        width: 150px;
    }
}
@media screen and (max-width: 374px) {
    .Navbar {
        font-size: 13px;
    }
    .nav-logo {
        width: 140px;
    }
}

.Amplify-signout {
    align-self: end;
    justify-self: end;
}

.nav-link {
    color: rgb(255, 255, 255) !important;
    text-align: left;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}